import React from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import Navbar from '../components/navbar';

interface IProps {
    isLoading?: boolean;
    alertMsg?: string | undefined;
    children?: React.ReactNode;
}

export default class PageTemplate extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);
    }

    renderContent = () => {
        if (this.props.isLoading) return <Spinner />;
        if (this.props.alertMsg) return (
            <Alert variant='danger'>
                {this.props.alertMsg}
            </Alert>
        );
        return this.props.children;
    }

    render() {
        return (
            <>
                <Navbar />
                <div className='page-template'>
                    {this.renderContent()}
                </div>
            </>
        );
    }
};
