import React from 'react';
import PageTemplate from './page_template';

export default class NotFound extends React.Component {
    render() {
        return (
            <PageTemplate>
                <h1>Page not found!</h1>
            </PageTemplate>
        );
    }
}
