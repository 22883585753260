import React from 'react';
import { NavLink } from 'react-router-dom';
import { GiHamburgerMenu, GiWineBottle } from 'react-icons/gi';
import { ImCross } from 'react-icons/im';

interface IProps {
}

interface IState {
    showNavbar: boolean;
}

export default class Navbar extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            showNavbar: false
        };
    }

    handleShowNavbar = () => {
        let showIt = !this.state.showNavbar;
        document.body.style.overflow = showIt ? 'hidden' : 'auto';
        this.setState({
            showNavbar: showIt
        });
    }

    handleNavigate = () => {
        document.body.style.overflow = 'auto';
    }

    handleShowRoot = () => {
        window.location.href = '/';
    }

    render() {
        return (
            <div className='nav-bar'>
                <GiWineBottle className='logo' size='1.5em' onClick={this.handleShowRoot} />
                {
                    this.state.showNavbar
                        ? <ImCross className='menu-icon' size='1.5em' onClick={this.handleShowNavbar} />
                        : <GiHamburgerMenu className='menu-icon' size='1.5em' onClick={this.handleShowNavbar} />
                }
                <div className={`nav-elements ${this.state.showNavbar && 'active'}`} onClick={() => {this.state.showNavbar && this.handleShowNavbar()}}>
                    <ul>
                        <li>
                            <NavLink onClick={this.handleNavigate} to='/'>Willkommen</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={this.handleNavigate} to='/heuriger'>Heuriger</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={this.handleNavigate} to='/winzer'>Winzer</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={this.handleNavigate} to='/weingut'>Weingut</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={this.handleNavigate} to='/kontakt'>Kontakt</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={this.handleNavigate} to='/anfahrt'>Anfahrt</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={this.handleNavigate} to='/bilder'>Bilder</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}
