import React from 'react';
import DivImage from './div_image';
import PageTemplate from '../components/page_template';

export default class Weingut extends React.Component {
    render() {
        return (
            <PageTemplate>
                <h2><p><strong>Das Weingut</strong></p></h2>
                <p>Seit 1953 führen wir den Weinbaubetrieb in der Enzesfelderstraße 16.</p>
                <p>Die Weingärten befinden sich am Lindenberg, wobei die Bodenbeschaffenheit von lehmig bis steinig reicht.</p>
                <DivImage src='keller.jpeg' maxWidth={400} mse='10px' mb='20px' />
                <h5><p><strong>Die Weinlese</strong></p></h5>
                <p>Der richtige Zeitpunkt bestimmt die Weinqualität.</p>
                <p>Die Weinlese beginnt bei uns etwa im September. Das Traubenmaterial wird von uns, unseren Erntehelfer sowie den Vertragswinzern von Hand gelesen. Aber auch im Frühjahr herrscht in den Weingärten absolute Hochsaison. Etwa im Juni wird begonnen die unerwünschten Triebe des Weinstocks zu entfernen damit der Wuchs gefördert wird. Auch werden die Fruchtansätze abgeschnitten um im Herbst eine gute Qualität zu erzielen.</p>
                <DivImage src='glas.jpeg' maxWidth={400} mse='10px' mb='20px' />
                <p>Im Weinbau sind viele Arbeitsgänge notwendig bis die Flasche schlussendlich beim Weintrinker ankommt. Dank intensiver Forschung ist es gelungen die Weinqualität ganz entscheidend zu verbessern. Somit gab es noch nie so gute und qualitativ hochwertige Weine wie heute.</p>
            </PageTemplate>
        );
    }
}
