import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './style.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import Welcome from './components/welcome';
import Pictures from './components/pictures';
import Heuriger from './components/heuriger';
import Winzer from './components/winzer';
import Weingut from './components/weingut';
import Kontakt from './components/contact';
import Anfahrt from './components/anfahrt';
import NotFound from './components/not_found';

const rootElement = document.getElementById('root');
if (rootElement) {
    const root = createRoot(rootElement!);
    root.render(
        <React.StrictMode>
            <BrowserRouter basename='/'>
                <Routes>
                    <Route path='/' element={<Welcome />} />
                    <Route path='/heuriger' element={<Heuriger />} />
                    <Route path='/winzer' element={<Winzer />} />
                    <Route path='/weingut' element={<Weingut />} />
                    <Route path='/kontakt' element={<Kontakt />} />
                    <Route path='/anfahrt' element={<Anfahrt />} />
                    <Route path='/bilder' element={<Pictures />} />
                    <Route path='/*' element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </React.StrictMode>
    );
}
