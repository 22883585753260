import React from 'react';
import { getPictures } from './div_image';
import PageTemplate from '../components/page_template';
import DivImage from '../components/div_image';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

interface IProps {
}

interface IState {
    isLoading: boolean;
}

export default class Pictures extends React.Component<IProps, IState> {
    pictures: { [key: string]: __WebpackModuleApi.RequireContext } = {};

    constructor(props: IProps) {
        super(props);
        this.state = {
            isLoading: true,
        };
    }

    async componentDidMount() {
        this.pictures = getPictures();
        this.setState({
            isLoading: false,
        });
        window.addEventListener('resize', () => {
            this.setState({});
        });
    }

    renderSlides = (useWidth: number, useHeight: number): any[] => {
        const items: any[] = [];
        for (const [key, value] of Object.entries(this.pictures)) {
            items.push(
                <SwiperSlide>
                    <DivImage
                        lazy
                        src={key}
                        dimensions={{ height: useHeight, width: useWidth }}
                        onClick={() => { window.open(`${process.env.REACT_APP_ASSETS_ROOT}/pictures/${key}`, '_blank', 'noreferrer') }}
                    />
                </SwiperSlide>
            );
        }
        return React.Children.toArray(items);
    }

    render() {
        // const ratio = 4 / 3;
        const ratio = 16 / 9;
        const innerWidth = window.innerWidth;
        const innerHeight = window.innerHeight - 175;
        let useWidth = innerWidth;
        let useHeight = useWidth / ratio;
        if (useHeight > innerHeight) {
            useWidth = innerHeight * ratio;
            useHeight = innerHeight;
        }
        return (
            <PageTemplate isLoading={this.state.isLoading}>
                <h2><p><strong>Bilder</strong></p></h2>
                <div className='pictures-container'>
                    <div style={{ width: `${useWidth}px` }}>
                        <Swiper
                            modules={[Autoplay, Navigation, Pagination]}
                            slidesPerView={1}
                            spaceBetween={30}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}
                            loop={true}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            height={useHeight}
                            children={this.renderSlides(useWidth, useHeight)}
                        />
                    </div>
                </div>
            </PageTemplate>
        );
    }
}
