import React from 'react';
import DivImage from './div_image';
import PageTemplate from '../components/page_template';

export default class Winzer extends React.Component {
    render() {
        return (
            <PageTemplate>
                <h2><p><strong>Der Winzer</strong></p></h2>
                <p>Richard Fischer wurde 1972 in Mödling in Niederösterreich in eine traditionelle Weinbaufamilie geboren. Nach der Weinbauschule Gumpoldskirchen begann er eine Lehre als Koch im Herzog Hof Baden.</p>
                <p>2001 übernahm er den elterlichen Traditionsbetrieb.</p>
                <DivImage src='richard.jpeg' maxWidth={400} mse='10px' mb='20px' />
                <h5><p><strong>Philosophie</strong></p></h5>
                <p>Qualität, Naturverbundenheit und Liebe zum Wein- das sind wesentliche Werte des Weinguts Fischer.</p>
                <p>Für uns bedeutet das, dass der Konsument beim genießen des Weines auch dessen Typizität, das Klima und die Lage, die Sorgfalt der Weingartenarbeit und der Vinifizierung spüren kann.</p>
                <p>Ich will keine verwechselbare internationale Modeweine, sondern ganz typisch Österreichische, Niederösterreichische Top- Qualität erschaffen. Weinmachen ist für mich das größte Glück für mich verbinden sich dabei Beruf und Hobby. Ich kann mir nichts Schöneres vorstellen.</p>
            </PageTemplate>
        );
    }
}
