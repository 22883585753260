type Options = {
    timeout?: number;
};

export const getImage = (url: string, options: Options = {}): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
        if (typeof window === 'undefined') {
            return reject('Window is not defined');
        }

        if (!url) {
            return reject('Url is not defined');
        }

        let timer : number | null | NodeJS.Timeout = null;

        const img = new Image();

        img.addEventListener('load', () => {
            if (timer) {
                clearTimeout(timer);
            }

            resolve(img);
        });

        img.addEventListener('error', (event) => {
            if (timer) {
                clearTimeout(timer);
            }

            reject(undefined);
        });

        img.src = url;

        if (options.timeout) {
            timer = setTimeout(() => reject(undefined), options.timeout);
        }
    });
};
