import React from 'react';
import DivImage from './div_image';
import PageTemplate from '../components/page_template';

export default class Heuriger extends React.Component {
    render() {
        return (
            <PageTemplate>
                <h2><p><strong>Der Heurige</strong></p></h2>
                <h5><p><strong>Unser Lokal</strong></p></h5>
                <DivImage src='heuriger.jpeg' maxWidth={400} mse='10px' mb='20px' />
                <p>Die Fichtenholzeinrichtung kombiniert mit alten Adlerziegeln verleiht unserem Lokal mit ca. 150 Sitzplätzen eine gemütliche Atmosphäre. Auch das „Stüberl“ mit 25 Sitzplätzen wird gerne von unseren Gästen für kleinere Feiern im privaten Rahmen genutzt. Durch die neue Überdachung unserer Terrasse mit ca. 100 Sitzplätzen können Sie im Sommer auch bei Nieselregen das Freie genießen.</p>
                <p>Natürlich bieten wir Ihnen unser Lokal auch außerhalb der Öffnungszeiten für Familien-. Betriebs-, Weihnachts- und Hochzeitsfeiern an. Durch ein persönliches Gespräch mit Richard Fischer jun. können Sie die Wünsche der Tischgestaltung und für Ihr Buffet besprechen. Bei Reisegruppen ab 50 Personen ersuchen wir Sie um telefonische Rücksprache.</p>
                <p>Da wir unsere Küche immer abwechslungsreich gestallten, besteht unsere Speisekarte aus Schmankerl der heimischen Heurigentradition, Spezialitäten der Saison sowie Gerichten der vegetarischen Küche.</p>
                <p>Auf Ihr Kommen freut sich die Familie Fischer.</p>
            </PageTemplate>
        );
    }
}
