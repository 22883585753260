import React from 'react';
import ReactPlayer from 'react-player';
import DivImage from './div_image';
import PageTemplate from '../components/page_template';
import DATES from '../assets/data/business_hours.json';

interface IProps {
}

interface IState {
    showVideo: boolean;
}

export default class Welcome extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            showVideo: false,
        };
    }

    async componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({});
        });
    }

    render() {
        let lastYear = '';
        let useWidth = window.innerWidth > 640 ? 640 : window.innerWidth;
        let playBtnSize = window.innerWidth > 640 ? 200 : (window.innerWidth / (640 / 200));
        return (
            <PageTemplate>
                <h2><p><strong>Herzlich Willkommen</strong></p></h2>
                <h2><p><strong>beim Heurigen Fischer in Leobersdorf...</strong></p></h2>
                <div className='welcome-video'>
                    {
                        this.state.showVideo
                            ? <ReactPlayer
                                maxwidth={640}
                                loop
                                playing
                                url='https://www.youtube.com/watch?v=campEtPzCOs'
                                onPause={() => this.setState({ showVideo: false })}
                            />
                            : <DivImage
                                blur={2}
                                src='terminator.jpg'
                                dimensions={{ height: 360, width: useWidth }}
                                onClick={() => this.setState({ showVideo: true })}
                                content={
                                    <img
                                        style={{ position: 'absolute' }}
                                        width={playBtnSize}
                                        height={playBtnSize}
                                        src={require('../assets/images/play_light.png')}
                                    />
                                }
                            />
                    }
                </div>
                <p>Aus g´steckt is</p>
                <p>FISCHER HEURIGER</p>
                {
                    React.Children.toArray(DATES.map((dates) => {
                        if (lastYear !== dates.year) {
                            lastYear = dates.year;
                            return (<>
                                <div style={{ fontWeight: 'bold' }}>{dates.year}</div>
                                <div>{`${dates.from} - ${dates.to}`}</div>
                            </>)
                        }
                        return (<div>{`${dates.from} - ${dates.to}`}</div>);
                    }))
                }
                <br />
                <p>Wir haben in diesem Zeitraum täglich von 11 bis 22 Uhr geöffnet.</p>
                <p>Wir freuen uns auf Ihren Besuch!</p>
                <a className='h2' style={{ color: '#ff9900' }} href={`${process.env.REACT_APP_ASSETS_ROOT}/files/ab_hof_wein.pdf`} target='blank'>
                    Aktuelle Weinliste zum Download
                </a>
            </PageTemplate>
        );
    }
}
