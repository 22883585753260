import React, { ReactNode } from 'react';
import { getImage } from '../helper/getImage';
import { Spinner } from 'react-bootstrap';

interface IProps {
    /* positioning */
    position?: string;
    left?: string;
    top?: string;
    right?: string;
    bottom?: string;
    ms?: string;
    mt?: string;
    me?: string;
    mb?: string;
    mse?: string;
    mtb?: string;
    ps?: string;
    pt?: string;
    pe?: string;
    pb?: string;
    pse?: string;
    ptb?: string;
    lazy?: boolean;

    /* image */
    src: string;
    width?: number;
    height?: number;
    dimensions?: {
        width: number;
        height: number;
    };
    maxWidth?: number;
    maxHeight?: number;
    
    /* content */
    content?: ReactNode;

    /* text */
    text?: string;
    fontSize?: string;
    fontColor?: string;

    /* filter */
    gray?: boolean | number;
    blur?: boolean | number;
    invert?: boolean | number;
    saturate?: boolean | number;
    contrast?: boolean | number;
    brightness?: boolean | number;
    sepia?: boolean | number;
    huerotate?: boolean | number;
    opacity?: boolean | number;

    onClick?: () => void;
}

interface IState {
    isLoading: boolean;
    image: HTMLImageElement | undefined;
    divCss: { [key: string]: any };
}

export default class DivImage extends React.Component<IProps, IState> {
    pictures: { [key: string]: __WebpackModuleApi.RequireContext } = {};
    image: HTMLImageElement | undefined = undefined;
    ratio: number = 1;

    constructor(props: IProps) {
        super(props);
        this.state = {
            isLoading: true,
            image: undefined,
            divCss: {},
        };
    }

    async componentDidMount() {
        this.pictures = getPictures();
        this.image = await getImage(this.pictures[this.props.src].toString());
        this.loadImage();
        window.addEventListener('resize', () => {
            this.loadImage();
        });
    }

    loadImage = () => {
        this.setState({ isLoading: true });
        if (!this.image) {
            return;
        }
        this.ratio = this.image.naturalWidth / this.image.naturalHeight;
        let dimensions = this.getDimensions(this.ratio);
        let divCss = this.getDivCss(dimensions);
        this.setState({
            isLoading: false,
            image: this.image,
            divCss: divCss,
        });
    }

    getDimensions = (ratio: number): { width: number; height: number; } => {
        let widthN = 0;
        let heightN = 0;

        if (this.props.dimensions) {
            widthN = this.props.dimensions.width;
            heightN = this.props.dimensions.width / ratio;
            if (heightN > this.props.dimensions.height) {
                widthN = this.props.dimensions.height * ratio;
                heightN = this.props.dimensions.height;
            }
        } else if (this.props.maxWidth || this.props.maxHeight) {
            let innerWidth = window.innerWidth;
            let innerHeight = window.innerHeight;
            if (this.props.maxWidth) {
                if (innerWidth > this.props.maxWidth) {
                    widthN = this.props.maxWidth;
                    heightN = (this.props.maxWidth / ratio);
                } else {
                    widthN = innerWidth;
                    heightN = (innerWidth / ratio);
                }
            } else if (this.props.maxHeight) {
                if (innerHeight > this.props.maxHeight) {
                    widthN = (this.props.maxHeight * ratio);
                    heightN = this.props.maxHeight;
                } else {
                    widthN = (innerHeight * ratio);
                    heightN = innerHeight;
                }
            }
        } else {
            if (!this.props.width && this.props.height) {
                widthN = this.props.height;
            }
            if (!this.props.height && this.props.width) {
                heightN = this.props.width;
            }
        }
        return {
            width: Math.floor(widthN),
            height: Math.floor(heightN)
        };
    }

    getDivCss = (dimensions: { width: number; height: number; }) => {
        if (this.props.lazy) {
            return this.addFilter({
                width: dimensions.width + 'px',
                height: dimensions.height + 'px',
            });
        }
        return this.addFilter({
            backgroundImage: `url(${this.pictures[this.props.src]})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: (dimensions.width + 'px' + ' ' + dimensions.height + 'px'),
            backgroundPosition: 'center',
            width: dimensions.width + 'px',
            height: dimensions.height + 'px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        });
    }

    addFilter = (cssObject: { [key: string]: any }) => {
        let filter: string[] = [];

        if (this.props.gray) {
            filter.push('grayscale(' + (typeof this.props.gray === 'number' ? this.props.gray : 100) + '%)');
        }

        if (this.props.blur) {
            filter.push('blur(' + (typeof this.props.blur === 'number' ? this.props.blur : 3) + 'px)');
        }

        if (this.props.invert) {
            filter.push('invert(' + (typeof this.props.invert === 'number' ? this.props.invert : 100) + '%)');
        }

        if (this.props.saturate) {
            filter.push('saturate(' + (typeof this.props.saturate === 'number' ? this.props.saturate : 3) + ')');
        }

        if (this.props.contrast) {
            filter.push('contrast(' + (typeof this.props.contrast === 'number' ? this.props.contrast : 100) + '%)');
        }

        if (this.props.brightness) {
            filter.push('brightness(' + (typeof this.props.brightness === 'number' ? this.props.brightness : 0.25) + ')');
        }

        if (this.props.sepia) {
            filter.push('sepia(' + (typeof this.props.sepia === 'number' ? this.props.sepia : 100) + '%)');
        }

        if (this.props.huerotate) {
            filter.push('hue-rotate(' + (typeof this.props.huerotate === 'number' ? this.props.huerotate : 180) + 'deg)');
        }

        if (this.props.opacity) {
            filter.push('opacity(' + (typeof this.props.opacity === 'number' ? this.props.opacity : 50) + '%)');
        }

        if (filter.length > 0) {
            cssObject = { ...cssObject, ...{ filter: filter.join(' '), WebkitFilter: filter.join(' ') } }
        }

        return cssObject;
    }

    render() {
        const mainCss: { [key: string]: any } = {
            cursor: this.props.onClick ? 'pointer' : 'default',
            WebkitUserSelect: 'none', /* Safari */
            msUserSelect: 'none', /* IE 10 and IE 11 */
            userSelect: 'none', /* Standard syntax */
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: this.props.fontColor,
            fontSize: this.props.fontSize,
            position: this.props.position,
            left: this.props.left,
            top: this.props.top,
            right: this.props.right,
            bottom: this.props.bottom,
            marginLeft: this.props.ms || this.props.mse,
            marginTop: this.props.mt || this.props.mtb,
            marginRight: this.props.me || this.props.mse,
            marginBottom: this.props.mb || this.props.mtb,
            paddingLeft: this.props.ps || this.props.pse,
            paddingTop: this.props.pt || this.props.ptb,
            paddingRight: this.props.pe || this.props.pse,
            paddingBottom: this.props.pb || this.props.ptb,
        };
        if (this.props.dimensions) {
            mainCss['height'] = this.props.dimensions.height;
            mainCss['width'] = this.props.dimensions.width;
        }

        if (this.state.isLoading || !this.state.image) {
            return (
                <div style={mainCss}>
                    <Spinner />
                </div>
            )
        }

        return (
            <div style={mainCss} onClick={this.props.onClick}>
                {
                    this.props.lazy
                        ? <div style={this.state.divCss} dangerouslySetInnerHTML={{ __html: this.state.image.outerHTML }} />
                        : <div style={this.state.divCss} />
                }
                {
                    this.props.text && this.props.text !== ''
                        ? <div style={{ position: 'absolute', fontSize: this.props.fontSize }}>{this.props.text}</div>
                        : undefined
                }
                {
                    this.props.content
                        ? this.props.content
                        : undefined
                }
            </div>
        );
    }
};

export function getPictures() {
    const context: __WebpackModuleApi.RequireContext = require.context('../assets/pictures/', false, /\.(png|jpe?g|svg)$/);
    let pictures: { [key: string]: __WebpackModuleApi.RequireContext } = {};
    context.keys().forEach(item => {
        pictures[item.replace('./', '')] = context(item);
    });
    return pictures;
}
